import axios, { AxiosError } from 'axios';
import { blipGoApiPassword, blipGoApiUrl, blipGoApiUser } from 'configurations/Environment';
import {
  ACTIVATION_CHOICE_ROUTE,
  APPROVAL_ROUTE,
  ATTENDANT_PERMISSION_ROUTE,
  BOT_CONFIGURATION_ROUTE,
  DELEGATE_ROUTE,
  DIRECTION_LEAD_FIND_BY_EMAIL,
  DIRECTION_LEAD_FIND_BY_INSTALLATION_ID_ROUTE,
  DOCUSIGN_SAVE_INFO_ROUTE,
  GET_ACTIVE_MESSAGES_ROUTE,
  GET_CAMPAIGNS_QUANTITY_ROUTE,
  GET_CONTACTS_ROUTE,
  GET_INFO_BY_INSTALLATION_ID_ROUTE,
  GET_MESSAGES_ROUTE,
} from 'constants/BlipGoApiRoutes';
import { DESK_MSGING_NET, POSTMASTER } from 'constants/CommandsApiRoutes';
import { BlipGoApi, Logger } from 'infra/adapters';
import { ApiResponse } from 'objects/interfaces/IRestService';
import { BotPermissions, CampaignsQuantity } from 'objects/types/BlipApi';
import {
  ActiveMessagesMetrics,
  AttendanceRegistry,
  IDirectionLead,
  IDocusignUserInfo,
  ILeadInfo,
  IUserInfo,
  PlanTypeEnum,
  QueueItemRegistry,
  QueueRegistry,
} from 'objects/types/BlipGoApi';
import { ContactsDto } from 'objects/types/ContactsResponse';
import { AttendanceHourDetailed, Off, WeekDay } from 'objects/types/humamService/AttendanceHour';
import { MessagesDto } from 'objects/types/MessagesResponse';
import { AttendanceHourContainer } from 'objects/types/Schedule';
import Attendant from 'objects/types/Attendant';

export const getIsEmailRegistered = async (email: string) => {
  const blipGoApi = new BlipGoApi('getIsEmailRegistered');

  const response = await blipGoApi.Get<IUserInfo>(`${APPROVAL_ROUTE}/${email}`, {
    auth: {
      username: blipGoApiUser,
      password: blipGoApiPassword,
    },
  });
  return response.data;
};

export const saveSignerInfo = async (
  email: string,
  position: string,
  fullName: string,
  cnpj: string,
  companyName: string,
  installationId: number,
  planType: PlanTypeEnum,
) => {
  const blipGoApi = new BlipGoApi('saveSignerInfo');

  const response = await blipGoApi.Post<IDocusignUserInfo>(
    DOCUSIGN_SAVE_INFO_ROUTE,
    { email, position, fullName, cnpj, companyName, installationId, planType },
    {
      auth: {
        username: blipGoApiUser,
        password: blipGoApiPassword,
      },
    },
  );
  return response;
};

export const directionLeadFindByInstallationId = async (installationId: string) => {
  const blipGoApi = new BlipGoApi('directionLeadFindByInstallationId');

  const response = await blipGoApi.Get<IDirectionLead>(
    `${DIRECTION_LEAD_FIND_BY_INSTALLATION_ID_ROUTE}/${installationId}`,
    {
      auth: {
        username: blipGoApiUser,
        password: blipGoApiPassword,
      },
    },
  );

  if (response === undefined) {
    return {} as IDirectionLead;
  }
  return response.data;
};

export const directionLeadFindByEmail = async (email: string) => {
  try {
    const url = `${blipGoApiUrl}${DIRECTION_LEAD_FIND_BY_EMAIL}/${email}`;
    const res = await axios.get<IDirectionLead>(url, {
      auth: {
        username: blipGoApiUser,
        password: blipGoApiPassword,
      },
    });
    return res.data;
  } catch (error) {
    const err = error as AxiosError;
    if (err.status !== 404) {
      Logger.Error(err.message, {
        className: 'BlipGoApiService',
        methodName: 'directionLeadFindByEmail',
      });
    }
    return null;
  }
};

export const getSignerInfo = async (installationId: string) => {
  try {
    const url = `${blipGoApiUrl}${GET_INFO_BY_INSTALLATION_ID_ROUTE}/${installationId}`;
    const res = await axios.get<IDocusignUserInfo[]>(url, {
      auth: {
        username: blipGoApiUser,
        password: blipGoApiPassword,
      },
    });

    return res.data;
  } catch (error) {
    const err = error as AxiosError;
    if (err.status !== 404) {
      Logger.Error(err.message, {
        className: 'BlipGoApiService',
        methodName: 'getSignerInfo',
      });
    }
    return null;
  }
};

export const saveUserInfo = async (email: string) => {
  const blipGoApi = new BlipGoApi('saveUserInfo');

  const response = await blipGoApi.Post<ILeadInfo>(APPROVAL_ROUTE, email, {
    auth: {
      username: blipGoApiUser,
      password: blipGoApiPassword,
    },
  });
  return response;
};

export const updateActivationChoice = async (activationChoice: string, email: string) => {
  const blipGoApi = new BlipGoApi('updateActivationChoice');

  const response = await blipGoApi.Put<ILeadInfo>(
    `${ACTIVATION_CHOICE_ROUTE}`,
    { email: email, activationType: activationChoice },
    {
      auth: {
        username: blipGoApiUser,
        password: blipGoApiPassword,
      },
    },
  );
  return response;
};

export const GetAttendanceRegistry = async (botId: string, value?: boolean) => {
  const blipGoApi = new BlipGoApi('GetAttendanceRegistry');

  try {
    const response = await blipGoApi.Get<AttendanceRegistry>(`/attendance/${botId}`, {
      auth: {
        username: blipGoApiUser,
        password: blipGoApiPassword,
      },
    });

    return response.data;
  } catch (error: any) {
    const err = error as AxiosError<ApiResponse<AttendanceRegistry>>;
    if (err.status === 404) {
      const res = await CreateAttendanceRegistry(botId, true, value ?? false);
      return res.data;
    }
    throw new Error(err.message);
  }
};

const CreateAttendanceRegistry = async (botId: string, isEnabled: boolean, isFulltime: boolean) => {
  const blipGoApi = new BlipGoApi('CreateAttendanceRegistry');
  const response = await blipGoApi.Post<AttendanceRegistry>(
    '/attendance',
    { botId, isEnabled, isFulltime },
    {
      auth: {
        username: blipGoApiUser,
        password: blipGoApiPassword,
      },
    },
  );

  return response;
};

export const UpdateIsFulltimeAttendance = async (botId: string, value: boolean) => {
  const blipGoApi = new BlipGoApi('UpdateIsFulltimeAttendance');
  await blipGoApi.Patch<void>(
    `/attendance/${botId}/toggle-fulltime`,
    { value },
    {
      auth: {
        username: blipGoApiUser,
        password: blipGoApiPassword,
      },
    },
  );
};

export const GetQueueRegistry = async (botId: string) => {
  const blipGoApi = new BlipGoApi('GetQueueRegistry');

  try {
    const response = await blipGoApi.Get<QueueRegistry>(`/queue/${botId}`, {
      auth: {
        username: blipGoApiUser,
        password: blipGoApiPassword,
      },
    });

    return response.data;
  } catch (error: any) {
    const err = error as ApiResponse<QueueRegistry>;
    if (err.status === 404) {
      return null;
    }
    throw new Error(JSON.stringify(err.data));
  }
};

export const CreateQueueRegistry = async (botId: string, listQueuesMessage: string, queues: QueueItemRegistry[]) => {
  const blipGoApi = new BlipGoApi('CreateQueueRegistry');

  const response = await blipGoApi.Post<QueueRegistry>(
    '/queue',
    { botId, listQueuesMessage, queues },
    {
      auth: {
        username: blipGoApiUser,
        password: blipGoApiPassword,
      },
    },
  );

  return response;
};

export const UpdateQueueItems = async (botId: string, listQueuesMessage: string, queues: QueueItemRegistry[]) => {
  const blipGoApi = new BlipGoApi('UpdateQueueRegistry');

  await blipGoApi.Patch<void>(
    `/queue/${botId}/update`,
    { queues, listQueuesMessage },
    {
      auth: {
        username: blipGoApiUser,
        password: blipGoApiPassword,
      },
    },
  );
};

export const SaveAttendanceSchedule = async (
  tenant: string,
  deskBotId: string,
  weekDays: WeekDay[],
  holidays: Off[],
) => {
  try {
    const body = {
      title: 'Horário de atendimento',
      description: 'Horário de atendimento padrão',
      isMain: true,
      queues: [],
      weekDays: weekDays,
      offs: holidays || [],
    };
    const hoursContainers = await getAttendanceHoursContainers(deskBotId, tenant);

    if (hoursContainers.totalItens > 0) {
      const currentAttendanceHourId = hoursContainers.itens[0].attendanceHourId;
      await deleteAttendanceHours(deskBotId, tenant, currentAttendanceHourId);
    }
    await createAttendanceHours(deskBotId, tenant, body);
    return true;
  } catch (ex) {
    return Promise.reject(ex);
  }
};

export const getAttendanceHoursDetailed = async (deskBotId: string, tenant: string, attendanceHourId: string) => {
  const blipGoApi = new BlipGoApi('GetAttendanceSchedule');
  try {
    const res = await blipGoApi.Get<AttendanceHourDetailed>(
      `/tenants/${tenant}/bots/${deskBotId}/attendance/hours/${attendanceHourId}`,
      {
        auth: {
          username: blipGoApiUser,
          password: blipGoApiPassword,
        },
      },
    );
    return res.data;
  } catch (ex) {
    return Promise.reject(ex);
  }
};

export const getAttendanceHoursContainers = async (deskBotId: string, tenant: string) => {
  const blipGoApi = new BlipGoApi('GetAttendanceContainerSchedule');
  try {
    const res = await blipGoApi.Get<AttendanceHourContainer>(`/tenants/${tenant}/bots/${deskBotId}/attendance/hours`, {
      auth: {
        username: blipGoApiUser,
        password: blipGoApiPassword,
      },
    });
    return res.data;
  } catch (ex) {
    return Promise.reject(ex);
  }
};

export const deleteAttendanceHours = async (deskBotId: string, tenant: string, attendanceHourId: string) => {
  const blipGoApi = new BlipGoApi('DeleteAttendanceHours');
  try {
    const res = await blipGoApi.Delete(`/tenants/${tenant}/bots/${deskBotId}/attendance/hours/${attendanceHourId}`, {
      auth: {
        username: blipGoApiUser,
        password: blipGoApiPassword,
      },
    });
    return res;
  } catch (ex) {
    return Promise.reject(ex);
  }
};

export const createAttendanceHours = async (deskBotId: string, tenant: string, body: any) => {
  const blipGoApi = new BlipGoApi('createAttendanceHours');
  try {
    const res = await blipGoApi.Post(`/tenants/${tenant}/bots/${deskBotId}/attendance/hours`, JSON.stringify(body), {
      auth: {
        username: blipGoApiUser,
        password: blipGoApiPassword,
      },
    });
    return res;
  } catch (ex) {
    return Promise.reject(ex);
  }
};

export const delegateBot = async (botId: string, botAccessKey: string) => {
  try {
    const blipGoApi = new BlipGoApi('delegateBot');
    const requestBody = {
      botId: botId,
      accessKey: botAccessKey,
    };

    await blipGoApi.Post(`/${DELEGATE_ROUTE}`, requestBody, {
      auth: {
        username: blipGoApiUser,
        password: blipGoApiPassword,
      },
    });

    return true;
  } catch (ex) {
    return Promise.reject(ex);
  }
};

export const getContacts = async (tenant: string, botId: string): Promise<ContactsDto> => {
  try {
    const blipGoApi = new BlipGoApi('getContacts');

    const currentDate = new Date();

    const lastMessageDateStart = new Date(currentDate);
    lastMessageDateStart.setDate(currentDate.getDate() - 90);

    const lastMessageDateEnd = new Date(currentDate);
    lastMessageDateEnd.setDate(currentDate.getDate() + 1);

    const lastMessageDateStartIso = lastMessageDateStart.toISOString();
    const lastMessageDateEndIso = lastMessageDateEnd.toISOString();

    const queryParams = `?LastMessageDateStart=${encodeURIComponent(
      lastMessageDateStartIso,
    )}&LastMessageDateEnd=${encodeURIComponent(lastMessageDateEndIso)}`;

    const response = await blipGoApi.Get<ContactsDto>(
      `/Tenants/${tenant}/Bots/${botId}/${GET_CONTACTS_ROUTE}${queryParams}`,
      {
        auth: {
          username: blipGoApiUser,
          password: blipGoApiPassword,
        },
      },
    );
    return response.data;
  } catch (ex) {
    return Promise.reject(ex);
  }
};

export const getMessages = async (tenant: string, botId: string, contactIdentity: string): Promise<MessagesDto> => {
  try {
    const blipGoApi = new BlipGoApi('getMessages');

    const response = await blipGoApi.Get<MessagesDto>(
      `/Tenants/${tenant}/Bots/${botId}/${GET_MESSAGES_ROUTE}?ContactIdentity=${contactIdentity}&pageSize=20`,
      {
        auth: {
          username: blipGoApiUser,
          password: blipGoApiPassword,
        },
      },
    );
    return response.data;
  } catch (ex) {
    return Promise.reject(ex);
  }
};

export const getActiveMessageMetrics = async (
  tenant: string,
  botId: string,
  intervalType: string,
  startDate: string,
  endDate: string,
) => {
  try {
    const blipGoApi = new BlipGoApi('getActiveMessageQuantity');

    const response = await blipGoApi.Get<ActiveMessagesMetrics>(
      `/Tenants/${tenant}/Bots/${botId}/${GET_ACTIVE_MESSAGES_ROUTE}/${intervalType}/Quantity`,
      {
        params: { startDate, endDate },
        auth: {
          username: blipGoApiUser,
          password: blipGoApiPassword,
        },
      },
    );
    return response.data;
  } catch (ex) {
    return Promise.reject(ex);
  }
};

export const getCampaignsQuantity = async (tenant: string, botId: string) => {
  try {
    const blipGoApi = new BlipGoApi('getActiveMessage');

    const response = await blipGoApi.Get<CampaignsQuantity>(
      `/Tenants/${tenant}/Bots/${botId}/${GET_CAMPAIGNS_QUANTITY_ROUTE}`,
      {
        auth: {
          username: blipGoApiUser,
          password: blipGoApiPassword,
        },
      },
    );
    return response.data;
  } catch (ex) {
    return Promise.reject(ex);
  }
};

export const getBotPermissions = async (tenant: string, botId: string) => {
  try {
    const blipGoApi = new BlipGoApi('getBotPermissions');

    const response = await blipGoApi.Get<BotPermissions>(
      `/Tenants/${tenant}/Bots/${botId}/${BOT_CONFIGURATION_ROUTE}?domain=${POSTMASTER}@${DESK_MSGING_NET}`,
      {
        auth: {
          username: blipGoApiUser,
          password: blipGoApiPassword,
        },
      },
    );
    return response.data;
  } catch (ex) {
    return Promise.reject(ex);
  }
};

export const setBotPermissions = async (tenant: string, botId: string, configuration: string, status: boolean) => {
  try {
    const blipGoApi = new BlipGoApi('setBotPermissions');
    const response = await blipGoApi.Post(
      `/Tenants/${tenant}/Bots/${botId}/${BOT_CONFIGURATION_ROUTE}`,
      { domain: `${POSTMASTER}@${DESK_MSGING_NET}`, configurations: { [configuration]: status } },
      {
        auth: {
          username: blipGoApiUser,
          password: blipGoApiPassword,
        },
      },
    );
    return response.data;
  } catch (ex) {
    return Promise.reject(ex);
  }
};

export const setAttendantsPermissions = async (
  tenant: string,
  botId: string,
  permission: any,
  status: boolean,
  attendants: Attendant[],
) => {
  try {
    const blipGoApi = new BlipGoApi('setAttendantsPermissions');
    const agents = attendants.map((agent: Attendant) => agent.email);
    const response = await blipGoApi.Post(
      `/Tenants/${tenant}/Bots/${botId}/${ATTENDANT_PERMISSION_ROUTE}`,
      { agents, permissions: [{ name: permission, isActive: status }] },
      {
        auth: {
          username: blipGoApiUser,
          password: blipGoApiPassword,
        },
      },
    );
    return response.data;
  } catch (ex) {
    return Promise.reject(ex);
  }
};
