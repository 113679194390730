export type IUserInfo = {
  readyToMeta: boolean;
  activation: string;
};

export type IDirectionLead = {
  id: string;
  installationId: number;
  nameMerchant: string;
  emailMerchant: string;
  leadSource: string;
  planType: PlanTypeEnum;
  productVersion: string;
  createdAt: Date;
};

export enum PlanTypeEnum {
  Free = 0,
  Basic = 1,
  Pro = 2,
}

export type IDocusignUserInfo = {
  id: string;
  email: string;
  signed: boolean;
  position: string;
  fullName: string;
  cnpj: string;
  companyName: string;
  installationId: number;
  createdAt: string;
  updatedAt: string;
  planType: PlanTypeEnum;
};

export type ILeadInfo = {
  id: string;
  email: string;
  inactive: boolean;
  readyToMeta: boolean;
  activation: string;
  createdAt: string;
  updatedAt: string;
};

export type AttendanceRegistry = {
  botId: string;
  isEnabled: boolean;
  isFulltime: boolean;
};

export type QueueRegistry = {
  botId: string;
  listQueuesMessage: string;
  queues: QueueItemRegistry[];
};

export type QueueItemRegistry = {
  id: string;
  name: string;
  redirectMessage: string;
};

export type ActiveMessagesMetrics = {
  intervalStart: string;
  intervalEnd: string;
  count: number;
};
