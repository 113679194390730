import { BdsGrid, BdsIcon, BdsSelect, BdsSelectOption, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import LayoutContainer from 'components/LayoutContainer/LayoutContainer';
import MainLayout from 'components/MainLayout/MainLayout';
import ExternalUrls from 'constants/ExternalUrls';
import { HOME_ROUTE } from 'constants/Routes';
import { useHashPrevention } from 'hooks/useHashPrevention';
import { Analytics } from 'infra/adapters';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useProject } from 'redux/slices/ProjectSlice/projectSlice';
import ActivateChannel from './components/ActivateChannel';
import CustomizeChatbot from './components/CustomizeChatbot';
import { BottomContainer } from './styles';

const Home: React.FC = () => {
  useHashPrevention(HOME_ROUTE);
  const { whatsAppActive, instagramActive, messengerActive } = useSelector(useProject);
  const [hasActiveChannel, setHasActiveChannel] = useState<boolean>(false);

  useEffect(() => {
    Analytics.Track(Analytics.events.PAGE_HOME_ACCESS);
    if (whatsAppActive || instagramActive || messengerActive) {
      setHasActiveChannel(true);
    }
  }, [whatsAppActive, instagramActive, messengerActive]);

  return (
    <MainLayout>
      <LayoutContainer>
        {hasActiveChannel ? <CustomizeChatbot /> : <ActivateChannel />}
        <BottomContainer>
          <BdsTypo variant="fs-12">
            © {new Date().getFullYear()} Blip Go! - Powered by Blip | Todos os direitos reservados |
            <a href={ExternalUrls.termsOfUseBlipGo} target="_blank" rel="noreferrer">
              &nbsp;Termos de Uso
            </a>
          </BdsTypo>
        </BottomContainer>
      </LayoutContainer>
    </MainLayout>
  );
};

export default Home;
